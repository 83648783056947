/* ------------------------------------------------------------ */
/* Admins page                                                  
/* ------------------------------------------------------------ */

.Admins-Title {
    text-align: left;
}

.Admins-Body {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 20px;
    padding-top: 20px;
}

/* ============================= */
/* ProductOwnerChanges Component */
/* ============================= */

.ProductOwnerChange-Body {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 20px;
}

.ProductOwnerChange-FilterBar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    gap: 30px;
    padding: 20px 0px;
}

.ProductOwnerChange-DatePickerBody {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    gap: 10px;
}

.ProductOwnerChange-DatePicker {
    display: block;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none;
}

.ProductOwnerChange-DatePicker:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}