/* ======================================= */
/* Onload Page and Components              */
/* ======================================= */

.Unsubscribe-Onload-Container {
    display: flex;
    flex-direction: column;
    text-align: left;
    grid-gap: 15px;
}

.Unsubscribe-Body {
    margin: 20px 0px;
}

/* ======================================= */
/* Page Success and Components             */
/* ======================================= */

.Unsubscribe-Success-Body {
    display: flex;
    flex-direction: column;
    font-size: var(--body);
    text-align: left;
    font-weight: 500;
    margin: 25px 0px;
}

.Unsubscribe-Textarea {
    border: none;
    width: 100%;
    min-height: 200px;
    height: 100%;
    resize: none;
    cursor: default;
    outline: none;
    font-weight: normal;
}