/* Default Styling */
.ProductAvailability-Field {
    font-size: var(--input);
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 500;
}

.ProductAvailability-Invalid-Message {
    font-weight: normal;
}

.ProductAvailability-Icon-Readonly-Selected {
    margin-right: 10px;
    border: 2px solid #0BAFBF;
    filter: grayscale(10);
    border-radius: .375rem;
    cursor: default;
}
