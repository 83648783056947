/* ========================================= */
/*  Active Subscriptions
/* ========================================= */

.ActiveTableRow-Expanded {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 50px;
    gap: 20px 0px;
    grid-template-areas:
        "ActiveTableRow-PTE ActiveTableRow-Support"
        "ActiveTableRow-Buttons ActiveTableRow-Ratings";
    padding: 10px calc(5% + 20px) 15px calc(5% + 20px);
    align-items: center;
    font-size: var(--table);
}

.ActiveTableRow-PTE {
    grid-area: ActiveTableRow-PTE;
    font-size: var(--table);
    padding-right: 30px;
}

.ActiveTableRow-Support {
    grid-area: ActiveTableRow-Support;
    font-size: var(--table);
    align-self: flex-start;
}

.ActiveTableRow-Buttons{
    grid-area: ActiveTableRow-Buttons;
    display: flex;
    flex-direction: row;
}

.ActiveTableRow-Ratings{
    grid-area: ActiveTableRow-Ratings;
    display: flex;
    flex-direction: column;
    font-size: var(--table);
    margin-bottom: 20px;
}

.ActiveTableRow-FieldLabel {
    color: #747575;
    margin: 15px 0px;
}

/* --------------------------------------- */
/*  Active Subscriptions > Edit Details
/* --------------------------------------- */

.ActiveTableRow-Pending {
    font-size: var(--table);
    padding: 15px;
}

.ActiveTableRow-Oracle-Project-label {
    font-weight: normal;
    font-style: italic;
}

/* ========================================= */
/* Pending Subscriptions 
/* ========================================= */

.MySubscriptions-Pending-Logo {
    padding: 10px;
    width: 50px;
    background-color: #f8f8f8;
    border-radius: 50%;
}

.PendingTableRow-Expanded {
    display: grid;
    grid-template-columns: 20% 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
        "PendingTableRow-Column1 PendingTableRow-Column2";
    padding: 10px calc(5% + 20px) 15px calc(5% + 20px);
}

.PendingTableRow-Column1 {
    grid-area: PendingTableRow-Column1;
    font-size: var(--table);
}

.PendingTableRow-Column2 {
    grid-area: PendingTableRow-Column2;
    font-size: var(--table);
}

.PendingTableRow-FieldLabel {
    color: #747575;
    margin: 10px 0px;
}

/* ========================================= */
/*  History                        
/* ========================================= */
