/* ------------------------------------------------------------ */
/** Tabular View Styling                                       **/
/* ------------------------------------------------------------ */

.TabView-Container {
    width: 100%;
    height: 100%;
    padding-top: 2rem;
}

.TabView-Nav {
    display: flex;
    flex-direction: row;
    border-bottom: #c1c5c969 2px solid;
    margin-bottom: 30px;
    font-weight: 500;
}

.TabView-Nav div {
    padding: 0px 10px 3px 10px;
    margin: 0px 50px 0px 0px;
    cursor: pointer;
}

.TabView-Nav-Default {
    cursor: pointer;
}

.TabView-Nav-Selected {
    cursor: pointer;
    border-bottom: 2px solid var(--teal);
    color: var(--teal);
}