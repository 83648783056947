/* ------------------------------------------------------------ */
/* License Bar Component                                               
/* ------------------------------------------------------------ */

.UsageBar-Container{
    border-radius: 5px;
    width: inherit;
    background-color: #e0e0de;
    height: 24px;
}


.UsageBar-Label{
    color:#fffffa;
    padding: 5px;
    font-size: 1rem;
}