/* ================================================ */
/*  Upload Button                                   */
/* ================================================ */

/* Shared styling */
.Upload-Error-Message {
    color: red;
    text-align: left;
}

/* Onload container */
.Upload-Onload-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
}


/* Success container */
.Upload-Container-Success {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: 150px;
    gap: 0px 5px;
    grid-template-areas:
        'Upload-Success-Icon Upload-Success-Edit-Icon';
    align-items: center;
    justify-content: center;
}

.Upload-Success-Icon {
    grid-area: Upload-Success-Icon;
    max-width: 100%;
    max-height: 100%;
}