/* ================================================ */
/* Products Edit                                    */
/* ================================================ */

.ProductsEditForm-Container {
    text-align: left;
    padding: 0px 15px;
}

.ProductsEditForm-Container Form {
    display: grid;
    grid-gap: 10px;
}

/* ------------------------------------------------ */
/* Form Styling                                     */
/* ------------------------------------------------ */

.ProductsEditForm-Pricing-Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3%;
    font-weight: 500;
    margin-bottom: 3%;
}

.ProductsEditForm-Pricing-Field {
    font-size: var(--input);
}

.ProductsEditForm-Pricing-ReadOnlyText {
    font-weight: 400;
    margin: 10px 0px;
}

.ProductsEditForm-Support-Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3%;
    font-weight: 500;
}

.ProductsEditForm-Licenses-Container {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    width: 70%;
}

.ProductsEditForm-OtherSettings-Container {
    font-weight: 500;
    min-width: 400px;
    max-width: 50%;
}

.ProductsEditForm-Buttons {
    margin-top: 4%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.ProductsEditForm-View {
    text-align: left;
    font-size: var(--body);

}

.ProductsEditForm-Oracle-Project-label {
    font-weight: normal;
    font-style: italic;
}
