/* Edit Styling */
.RichTextArea{
    border: 1px solid #ced4da;
    border-radius: .375rem;
    padding: .375rem .75rem;
    color: #212529;
}

/* Valid Styling */
.RichTextArea-Valid{
    border: 1px solid #198754;
    padding: .375rem .75rem;
    border-radius: .375rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px top 55px;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

/* Invalid Styling */
.RichTextArea-Invalid{
    border: 1px solid #dc3545;
    padding: .375rem .75rem;
    border-radius: .375rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px top 55px;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

/* ReadOnly Styling */
.RichTextArea-ReadOnly {
    max-height: 55vh;
    overflow-y: scroll;
}
.RichTextArea-ReadOnly::-webkit-scrollbar {
    width: 10px;
}
.RichTextArea-ReadOnly::-webkit-scrollbar-track {
    background: #f3f3f3;
    margin-bottom: 10px;
    border-radius: 10px;
}
.RichTextArea-ReadOnly::-webkit-scrollbar-thumb {
    background: #c1c5c969;
    border-radius: 10px;
}