/* ======================================= */
/* Onload Page and Components              */
/* ======================================= */

.MyCustomers-Container {
    text-align: left;
    display: grid;
    grid-template-rows: 200px 1fr;
    grid-template-areas:
        'MyCustomers-Header-Container'
        'MyCustomers-Body-Container';
}

.MyCustomers-Header-Container {
    grid-area: MyCustomers-Header-Container;
    display: grid;
    grid-template-rows: 50px 1fr 1fr;
    grid-template-columns: 100px 1fr 1fr;
    grid-template-areas:
        'Arrow . .'
        'Icon Text Text'
        'Divider Divider Divider';
}

.MyCustomers-Header-Arrow {
    grid-area: Arrow;
}

.MyCustomers-Header-Icon {
    grid-area: Icon;
    display: flex;
    justify-content: center;
    padding: 3%;
}

.MyCustomers-Header-Text {
    grid-area: Text;
    margin-left: 3%;
}

.MyCustomers-Header-Divider {
    grid-area: Divider;
    margin-top: 3%;
}

.MyCustomers-Body-Container {
    grid-area: MyCustomers-Body-Container;
    margin: 1%;
}

/* ======================================= */
/* Component - ActiveTable                 */
/* ======================================= */

.MyCustomers-ActiveTable-Container {
    margin-top: 1%;
}

.MyCustomers-ActiveTable-Container h4 {
    margin-bottom: 2%;
}

.MyCustomers-Active-Customers {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: var(--subheading);
    font-weight: 500;
}

/* ======================================= */
/* Component - InactiveTable               */
/* ======================================= */

.MyCustomers-InactiveTable-Container {
    margin-top: 3%;
}

.MyCustomers-InactiveTable-Container h4 {
    margin-bottom: 2%;
}