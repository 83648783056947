/* ------------------------------------------------------------ */
/** Page Header Styling                                        **/
/* ------------------------------------------------------------ */

.PageHeader-Container {
    width: 100%;
    text-align: left;
}

.PageHeader-Breadcrumbs {
    padding-bottom: 10px;
}

.PageHeader-Heading {
    display: flex;
    flex-direction: row;
    font-size: var(--heading);
    font-weight: 500;
}

.PageHeader-Description {
    font-size: var(--labels);
    padding: 10px 0px 15px 0px;
    display: -webkit-box; 
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: horizontal;
    overflow: hidden;
    text-overflow: ellipsis;
    /* height: 3.5rem; */
}

/* ---------------------------------------------- */
/** Page Header With Icon                        **/
/* ---------------------------------------------- */

.PageHeaderWithIcon-Container {
    display: grid;
    grid-template-columns: 90px 1fr;
    grid-template-rows: 1fr;
    gap: 0px 15px;
    grid-template-areas:
        "PageHeader-Icon PageHeader-Text";
    align-items: center;
    justify-self: center;
}

.PageHeader-Icon {
    grid-area: PageHeader-Icon;
    width: 85px;
    height: 85px;
}

.PageHeader-Text {
    grid-area: PageHeader-Text;
}

.Page-Icon{
    max-width: 100%;
    max-height: 100%;
}

.Divider{
    border-top: 2px solid #cfcfcf;
    min-width: 240px
}
