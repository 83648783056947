/* Shopping Cart */
.PricingTotal-ShoppingCart{
    display: flex;
    flex-direction: column;
}
.PricingTotal-ShoppingCart-Item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Default */
.PricingTotal-Container {
    display: flex;
    flex-direction: row;
}