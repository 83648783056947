/* Default Styling */
.ProductAvailability-Field {
    font-size: var(--input);
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 500;
}

.ProductAvailability-Invalid-Message {
    font-weight: normal;
}

.ProductAvailability-Icon {
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid #ced4da;
    border-radius: .375rem;
}

.ProductAvailability-Icon:hover {
    transform: scale(1.01);
}

.ProductAvailability-Icon-Selected {
    margin-right: 10px;
    border: 2px solid #0BAFBF;
    border-radius: .375rem;
}
