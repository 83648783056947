.RatingsSimple-Container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 140px;
    grid-template-rows: 1fr;
    gap: 20px 0px;
    align-items: center;
    grid-template-areas:
        "RatingsSimple-ClickToRate";
}

.RatingsSimple-ClickToRate {
    grid-area: RatingsSimple-ClickToRate;
    display: flex;
    flex-direction: column;
    gap: 20px 30px;
}

.RatingsSimple-AverageRating {
    grid-area: RatingsSimple-AverageRating;
    margin-left: 1px;
    height: 35px;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    align-items: end;
    margin-top: 14px;
}

a1 {
    grid-area: RatingsSimple-AverageRating;
    font-size: 14px;
    font-weight: 350;
    width: 100%;
    height: 10px;
    margin-left: 3px;
    align-items: end;
}

.On {
    color: #000;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.Off {
    color: #ccc;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}