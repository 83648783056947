.RatingsDetail-Container {
    display: grid;
    grid-template-columns: 30px 300px;
    grid-template-rows: fit-content 1fr;
    gap: 20px 0px;
    grid-template-areas:
        "RatingsCard-Expanded RatingsDetail-AverageRating"
        "RatingsDetail-ClickToRate RatingsDetail-ClickToRate";
    align-items: end;
    justify-items: start;
}

.RatingsDetail-ClickToRate {
    grid-area: RatingsDetail-ClickToRate;
    display: flex;
    flex-direction: column;
    gap: 20px 30px;
}

.RatingsDetail-AverageRating {
    grid-area: RatingsDetail-AverageRating;
    margin-left: 1px;
    height: 35px; 
    font-size: 30px;
    font-weight: 500;
    width: 100%; 
    align-self: end;
    justify-self: start;    
}

a1 {
    grid-area: RatingsDetail-AverageRating;
    font-size: 16px;
    font-weight: 350;
    width: 100%;
    height: 10px;
    margin-left: 3px;
    align-self: center;    
}

.RatingsCard-Expanded {
    grid-area: RatingsCard-Expanded;
}

.RatingsCard-Expanded img{
    height: 20px;
    width: 20px;
    align-items: center;
}

.On {
    color: #000;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.Off {
    color: #ccc;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.RatingsProgressBar-Container{
    display: flex; 
    flex-direction: column;
    gap: 15px 5px; 
    margin: 0px 10px;
    align-items: center;
    justify-items: stretch;
    margin-bottom: 15px;
}

.RatingsProgressBar {
    display: flex;
    flex-direction: row;
    gap: 15px 15px; 
    width: 380px; 
    height: 15px; 
    justify-items: start;    
}