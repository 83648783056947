/* ------------------------------------------------------------ */
/** Tooltip Styling                                            **/
/* ------------------------------------------------------------ */

/* One class for each alignment option */

.Tooltip-Text-TopLeft {
    text-align: left;
    width: fit-content;
    min-width: 300px;
    background-color: var(--tooltip-background-colour, #394E54);
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.4;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    z-index: 100;

    /* Position the tooltip */
    position: absolute;
    left: 30px;
}

.Tooltip-Text-TopRight {
    text-align: left;
    width: fit-content;
    min-width: 300px;
    background-color: var(--tooltip-background-colour, #394E54);
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.4;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    z-index: 100;

    /* Position the tooltip */
    position: absolute;
    right: 30px;
}

.Tooltip-Text-BottomLeft {
    text-align: left;
    width: fit-content;
    min-width: 300px;
    background-color: var(--tooltip-background-colour, #394E54);
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.4;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    z-index: 100;

    /* Position the tooltip */
    position: absolute;
    left: 30px;
    bottom: 0px;
}

.Tooltip-Text-BottomRight {
    text-align: left;
    width: fit-content;
    min-width: 300px;
    background-color: var(--tooltip-background-colour, #394E54);
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.4;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    z-index: 100;

    /* Position the tooltip */
    position: absolute;
    right: 30px;
    bottom: 0px;
}