.Notification-Container {
    display: grid;
    width: 100%;
    grid-template-columns: 80px 1fr 50px;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "Notification-Icon Notification-Message Notification-Close";
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    padding: 8px 0px;
    font-size: 0.875rem;
    color: black;
    line-height: 1.4;
}

.Notification-Icon {
    grid-area: Notification-Icon;
    justify-self: center;
}

.Notification-Message {
    grid-area: Notification-Message;
    font-size: 14px;
    text-align: left;
}

.Notification-Close {
    grid-area: Notification-Close;
    justify-self: center;
    cursor: pointer;
}