/* CSS Rules */

/* Import Web Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

/* CSS Variables */
:root {
    /* Font Sizes */
    --heading: 1.75rem;
    --subheading: 1.25rem;
    --body: 1rem;
    --input: .90rem;
    --navlinks: 1.125rem;
    --labels: 0.95rem;
    --table: 0.95rem;

    /* Colours */
    --teal: #00B8B5;
    --orange: #FA8836;
    --blue: #0C6CDE;
    --lightgrey: #E9E9E9;
    --darkgrey: #394E54;
    --lightergrey: #F3F4F6;
    --tableheader: #F0F0F0;
    --tableborder: #B9B9B9;
}

/* Rule - Images should never be rendered at a size wider than their containing element using max-inline-size */
img {
    max-inline-size: 100%;
    block-size: auto;
}

/* ------------------------------------------------------ */
/** Text standard                                        **/
/*  Purpose: Standards for titles, subtitles and body     */
/* ------------------------------------------------------ */

/* Fonts */
* {
    font-family: 'Roboto', sans-serif;
}

/* Heading */
h1 {
    font-size: var(--heading);
}

/* Subheading */
h2 {
    font-size: var(--subheading);
}

/* Body */
div {
    font-size: var(--body);
}

.Subheading {
    font-size: var(--subheading);
    font-weight: 500;
}

/* ------------------------------------------------------ */
/** Primary Button (Teal Background & White Text)        **/
/*  Purpose: All button components that perform a basic 
            action on the ITM. (e.g. submitting a form, 
            subscribing to a product)                     */
/* ------------------------------------------------------ */

.Primary-Button {
    border: 2.5px solid var(--teal);
    background-color: var(--teal);
    color: white;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 5px 10px 5px 0px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    font-size: var(--body);
    font-weight: 500;
}

.Primary-Button:disabled {
    background-color: var(--lightgrey);
    border: 2.5px solid var(--lightgrey);
    color: white;
}

.Primary-Button:hover {
    transform: scale(1.03);
    cursor: pointer;
    color: white;
}

/* ------------------------------------------------------ */
/** Secondary Button (Orange Fill & White Text)          **/
/*  Purpose: Use this secondary action, e.g. cancel       */
/* ------------------------------------------------------ */

.Secondary-Button {
    border: 2.5px solid var(--orange);
    background-color: var(--orange);
    color: white;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 5px 10px 5px 0px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    font-size: var(--body);
    font-weight: 500;
}

.Secondary-Button:disabled {
    background-color: var(--lightgrey);
    border: 2.5px solid var(--lightgrey);
    color: white;
}

.Secondary-Button:hover {
    transform: scale(1.03);
    cursor: pointer;
    color: white;
}

/* ------------------------------------------------------ */
/*  Status Label                                          */
/* ------------------------------------------------------ */

.Status-Label {
    padding: 2px 10px;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
}

/* ------------------------------------------------------ */
/*  Form Styling                                          */
/* ------------------------------------------------------ */

.Form.Group {
    margin: 15px 10px;
    padding: 1.5em;
}

/* ----- Field Styling ----- */

.Label-Field-Title {
    margin: 25px 0px 0px 0px;
    font-size: var(--body);
}

.Label-Field-Value {
    font-size: var(--body);
    color: #929292;
}

/* ------------------------------------------------------ */
/*  Page Styling                                          */
/* ------------------------------------------------------ */

.Divider {
    border-top: 2px solid #cfcfcf;
    min-width: 240px
}

/* ------------------------------------------------ */
/* Table Pane Styling                               */
/* ------------------------------------------------ */

/* Use this to show the grey background behind a table */
.Table-Pane {
    background-color: var(--lightergrey);
    border-radius: 5px;
    padding: 30px;
    text-align: left;
}

/* Use this to show a table title inside the grey table pane */
.Table-Title {
    display: flex;
    flex-direction: row;
    font-size: var(--subheading);
    font-weight: 500;
    margin-bottom: 10px;
}

/* Use this to show a subheading above each table */
.Table-Subheading {
    display: flex;
    flex-direction: row;
    font-size: var(--body);
    margin-top: 30px;
}

/* ------------------------------------------------ */
/* Data Table Styling                               */
/* ------------------------------------------------ */

.DataTable-Container {
    font-size: var(--table);
    width: 100%;
    background-color: white;
    border: 1px solid var(--tableborder);
    border-radius: 2px;
    box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
}

.DataTable-Container th {
    background-color: var(--tableheader);
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid var(--tableborder);
}

.DataTable-Container td {
    padding: 5px 10px;
    border: 1px solid var(--tableborder);
}

/* ------------------------------------------------ */
/* Interactive Table Styling                        */
/* ------------------------------------------------ */

.InteractiveTable-Container {
    font-size: 0.95rem;
    width: 100%;
    background-color: white;
    border: 1px solid var(--tableborder);
    border-radius: 2px;
    box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
}

.InteractiveTable-Container tr {
    border: 1px solid var(--tableborder);
}

.InteractiveTable-Container th {
    background-color: var(--tableheader);
    font-weight: 500;
    padding: 5px 10px;
}

.InteractiveTable-Container td {
    padding: 5px 10px;
}

/* ------------------------------------------------ */
/* Expandable Table Styling                         */
/* ------------------------------------------------ */

.ExpandableTable-Container {
    font-size: 0.95rem;
    width: 100%;
    background-color: white;
    border: 1px solid var(--tableborder);
    border-radius: 2px;
    box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
}

.ExpandableTable-Container th {
    background-color: var(--tableheader);
    font-weight: 500;
    padding: 5px 10px;
}

.ExpandableTable-Container td {
    padding: 5px 10px;
}

.ExpandableTable-Row-Expanded {
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
    background-color: #fdfdfd;
}

.Expand-Icon:hover {
    cursor: pointer;
}

.ExpandableTable-Container-Row {
    border: 1px solid var(--tableborder);
    cursor: pointer;
}

.ExpandableTable-Container-Row-Expanded {
    border: 1px solid var(--tableborder);
}

/* ------------------------------------------------------------------- */
/*  Custom Scroll bBar                                                 */
/*  https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp     */
/* ------------------------------------------------------------------- */

.Custom-Scroll-Bar {
    overflow-y: auto;
}

.Custom-Scroll-Bar::-webkit-scrollbar {
    width: 10px;
}

.Custom-Scroll-Bar::-webkit-scrollbar-track {
    background: #f3f3f3;
}

.Custom-Scroll-Bar::-webkit-scrollbar-thumb {
    background: #00B8B5;
    border-radius: 10px;
}