/* ------------------------------------------------------------ */
/** NavBar Styling                                             **/
/* ------------------------------------------------------------ */

.Navbar-Container {
    background-color: #141C25;
    color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* ------------------------------------------- */
/** Logo Header                               **/
/* ------------------------------------------- */

.Navbar-Logo {
    display: flex;
    align-items: center;
    padding: 25px 25px 15px 25px;
}

.Navbar-Logo img {
    margin-right: 15px;
    cursor: pointer;
}

.Navbar-Logo img:hover {
    margin-right: px;
    cursor: pointer;
    animation-name: spin;
    animation-duration: 1.2s;
    animation-delay: .5s;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* IT Marketplace Link */
.Navbar-Link {
    color: white;
    text-decoration: none;
    font-weight: normal;
    font-size: 1.35rem;
}

.Navbar-Link:hover {
    color: white;
    transform: scale(1.02);
}

/* ------------------------------------------- */
/*  Nav Items                                  */
/*  - Container for all sidebar items          */
/* ------------------------------------------- */

.Navbar-Items-Container {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--navlinks);
}

/* Each item in the sidebar */
.Navbar-Items-Container-Link {
    font-size: var(--navlinks);
    width: calc(100% - 15px);
    padding: 15px 20px;
    text-decoration: none;
    color: white;
    margin-left: 15px;
}

.Navbar-Items-Container-Link:hover {
    cursor: pointer;
    transform: scale(1.02);
    color: white;
}

.Navbar-Items-Container-Link-Active {
    font-size: var(--navlinks);
    width: calc(100% - 15px);
    padding: 15px 20px;
    text-decoration: none;
    color: white;
    border-radius: 5px 0px 0px 5px;
    background-color: #3A4149;
    transition-delay: .1s;
    cursor: default;
    margin-left: 15px;
}

.Navbar-Items-Container-Link-Active:hover {
    color: white;
}

.Navbar-Item-Icon{
    padding-right: 10px;
}