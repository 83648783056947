/* ------------------------------------------------------ */
/*  Login Page Styling (Unauthenticated State)            */
/* ------------------------------------------------------ */


.LoginHandler-Container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;
    align-items: stretch;
    min-height: 100vh;
}

.LoginHandler-Header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    padding: 10px;
    gap: 10px;
    height: min-content;
}

.LoginHandler-Body {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.LoginHandler-Content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    gap: 30px;
}

.LoginHandler-Graphic {
    width: 100%;
    max-width: 50vw;
}

.LoginHandler-Button-Container {
    display: flex;
    flex-direction: row;
}

.LoginHandler-Button {
    border: 2.5px solid var(--teal);
    background-color: var(--teal);
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 10px 5px 0px;
    font-weight: 600;
    font-size: var(--body);
    cursor: pointer;
}

.LoginHandler-Button:hover {
    transform: scale(1.03);
}

.LoginHandler-Button-Dropdown {
    border: 2.5px solid var(--teal);
    background-color: white;
    color: var(--teal);
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 10px 5px 0px;
    font-weight: 600;
    font-size: var(--body);
    position: relative;
    cursor: default;
    width: 191px;
}

.LoginHandler-Button-Dropdown:hover {
    box-shadow: none;
    background-color: white;
    border: 2.5px solid white;
}

.LoginHandler-Button-Dropdown:hover .LoginHandler-Button-Dropdown-Content {
    display: block;
    transition-delay: 1s;
}

.LoginHandler-Button-Dropdown-Content {
    display: none;
    border: 2.5px solid var(--teal);
    background-color: white;
    color: var(--teal);
    width: 250px;
    z-index: 999;
    font-size: var(--body);
    border-radius: 5px;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 190px;
    padding: 5px 10px;
}

.LoginHandler-Button-Dropdown-Content a {
    display: block;
    color: var(--teal);
    padding: 12px 16px;
    text-decoration: none;
    z-index: 999;
}

.LoginHandler-Button-Dropdown-Content a:hover {
    transform: scale(1.03);
    border-radius: inherit;
}

.LoginHandler-Footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-items: justify;
    background-color: #333333;
    padding: 30px 10px 10px;
    gap: 30px;
    height: min-content;
}

.LoginHandler-Footer-Feature {
    display: grid;
    flex-grow: 1;
    width: 280px;
    grid-template-rows: min-content auto;
    grid-template-columns: 100px auto;
}

.LoginHandler-Footer-Feature-Icon {
    display: block;
    margin: auto;
    grid-area: 1/1/3/2;
}

.LoginHandler-Footer-Feature-Title {
    grid-area: 1/2/2/3;
    color: #FDFDFD;
}

.LoginHandler-Footer-Feature-Text {
    grid-area: 2/2/3/3;
    color: #FDFDFD;
}

/* ===============================================

    Failed and Error pages

    Generic grid that supports 2 columns and 3 rows, layout is below:
        'grumpy-cat title'
        'grumpy-cat solution'
        'grumpy-cat button'
        'error error' 

=============================================== */

.LoginHandler-Failed-Container {
    display: grid; 
    text-align: left;
    padding: 15%;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
        'LoginHandler-Failed-Img LoginHandler-Failed-Title'
        'LoginHandler-Failed-Img LoginHandler-Failed-Solution'
        'LoginHandler-Failed-Img LoginHandler-Failed-Button'
        'LoginHandler-Failed-Error LoginHandler-Failed-Error';
}

.LoginHandler-Failed-Img{
    grid-area: LoginHandler-Failed-Img;
    max-width: 550px;
}
.LoginHandler-Failed-Title{
    grid-area: LoginHandler-Failed-Title;
    padding-left: 5%;
}
.LoginHandler-Failed-Solution{
    grid-area: LoginHandler-Failed-Solution;
    padding-left: 5%;
}
.LoginHandler-Failed-Button{
    grid-area: LoginHandler-Failed-Button;
    padding-left: 5%;
}
.LoginHandler-Failed-Error{
    grid-area: LoginHandler-Failed-Error;
    text-align: center;
    padding-top: 5%;
}

/* ===============================================
    Pending pages containers
        'pending'
        'pending-signin'
=============================================== */

.LoginHandler-Pending{
    text-align: center;
    padding: 10% 15%;
}