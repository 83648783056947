/* Default Styling */
.PricingModel-Field {
    font-size: var(--input);
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 500;
}

.PricingModel-Invalid-Message{
    font-weight: normal;
}

.PricingModel-Icon {
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid #ced4da;
    border-radius: .375rem;
}

.PricingModel-Icon:hover {
    transform: scale(1.01);
}

.PricingModel-Icon-Selected {
    margin-right: 10px;
    border: 2px solid #0BAFBF;
    border-radius: .375rem;
}

/* Read Only */
.PricingModel-ReadOnly-Container {
    filter: grayscale(10);
}

.PricingModel-Icon-Readonly-Selected {
    margin-right: 10px;
    border: 2px solid #0BAFBF;
    border-radius: .375rem;
    cursor: default;
}

.PricingModel-Icon-Readonly {
    margin-right: 10px;
    cursor: default;
    border: 2px solid #ced4da;
    border-radius: .375rem;
}