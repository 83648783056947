/* ================================================ */
/*  Onload Components                               */
/* ================================================ */

.Publish-Container-Onload {
    display: grid;
    grid-template-rows: fit-content 1fr 1fr;
    grid-template-areas:
        'Publish-Header'
        'Publish-Video'
        'Publish-Agreement';
    grid-gap: 0px;
    text-align: left;
}

.Publisher-Header{
    grid-area: Publish-Header;
}

/* ----------------------------------------------------- */
/* Video Components                                      */
/* ----------------------------------------------------- */

.Publish-Video {
    grid-area: Publish-Video;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Publish-Subheading{
    font-size: var(--subheading);
    font-weight: 500;
    margin: 15px 0px;
}

/* ----------------------------------------------------- */
/* Publisher Agreement Components                        */
/* ----------------------------------------------------- */

.Publish-Agreement {
    grid-area: Publish-Agreement;
}

.Publish-Acceptance {
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
}

.TermsConditions-Container {
    display: grid;
    grid-gap: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 60vh;
}

.TermsConditions-Container object {
    width: 100%;
    height: 60vh;
}

.TermsConditions-Container img {
    width: 20vh;
    height: 20vh;
}

/* ================================================ */
/* Publish Form Components
/* ================================================ */

.PublishForm-Container {
    text-align: left;
}

.PublishForm-Container Form {
    display: grid;
    grid-gap: 10px;
}

.PublishForm-Body-Items {
    margin-bottom: 3%;
}

.PublishForm-ProductOwner-Input-Container {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: flex-start;
}

.PublishForm-Input-Field{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.PublishForm-Input-Field:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.PublishForm-Input-Field-Error{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dc3545;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.PublishForm-ErrorMessage{
    color: #dc3545;
    font-size: .875em;
    margin-top: 0.25rem;
    width: 100%;
}

.PublishForm-WarningMessage{
    background-color: #f0e1e2;
    font-size: .875em;
    margin-top: 0.5rem;
    width: calc(100% + 50px);
    padding: 10px 20px;
    border-radius: 0.375rem;
}

.PublishForm-ProductOwner-Container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 24vh;
    overflow-y: auto;
    margin: 10px 04px;
}

.PublishForm-ProductOwner-Container::-webkit-scrollbar {
    width: 10px;
}

.PublishForm-ProductOwner-Container::-webkit-scrollbar-track {
    background: #f3f3f3;
}

.PublishForm-ProductOwner-Container::-webkit-scrollbar-thumb {
    background: var(--teal);
    border-radius: 10px;
}

.PublishForm-ProductOwner-Label-Container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: 15px;
    width: 100%;
}

.PublishForm-ProductOwner-Label {
    background-color: #E3F6F5;
    padding: 10px 20px;
    border-radius: 5px;
    width: 50%;
}

.PublishForm-ProductOwner-Label-Disabled{
    background-color: #F2F0F0;
    padding: 10px 20px;
    border-radius: 5px;
    width: 50%;
}

.PublishForm-Pricing-Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3%;
    font-weight: 500;
    margin-bottom: 3%;
}

.PublishForm-Field {
    font-size: var(--input);
    margin-bottom: 10px;
}

.PublishForm-Invalid-Message{
    font-weight: normal;
}

.PublishForm-Licenses-Container {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    width: 70%;
}

.PublishForm-Support-Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3%;
    font-weight: 500;
}

.PublishForm-OtherSettings-Container {
    font-weight: 500;
    min-width: 400px;
    max-width: 50%;
}

.PublishForm-Buttons {
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.PublishForm-Oracle-Project-label {
    font-weight: normal;
    font-style: italic;
}

/* ================================================ */
/* Publish Form Success Screen
/* ================================================ */

.PublishForm-View-Container {
    text-align: left;
    font-size: var(--body);
    margin-top: 2%;
}