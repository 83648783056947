.MultiLineDot-Container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.MultiLineDot-Container img{
    margin-left: 5px;
    cursor: pointer;
}
.MultiLineDot-Button{
    color: var(--teal);
    background-color: white;
    border: 1px solid var(--teal);
    border-radius: 5px;
    padding: 5px 18px;
    cursor: pointer;
    font-size: var(--body);
    margin: 5px;
}
.MultiLineDot-Button:hover{
    transform: scale(1.03);
}

.MultiLineDot-ErrorMessage{
    color: #dc3545;
    font-size: .875em;
    margin-top: 0.25rem;
    width: 100%;
}

.MultiLineDot-InputField{
    display: grid;
    width: 100%;
    grid-template-columns: 150px 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    margin: 5px;
    gap: 10px 10px;
}