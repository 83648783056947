/* ------------------------------------------------------------ */
/** App Styling                                                **/
/* ------------------------------------------------------------ */

/* Ensures if you change the box-sizing of an element, everything inside the element will inherit the box-sizing of that element */
*,
*:before,
*:after {
    box-sizing: inherit;
}

.App-Container{
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    'App-Container-Nav App-Container-Body';
}

.App-Container-Nav{
    grid-area: App-Container-Nav;
    position: fixed;
    width: 300px;
}

.App-Container-Body{
    grid-area: App-Container-Body;
    padding: 2rem 3rem;
}