/* ================================================ */
/* Customer Requests Styling                        */
/* ================================================ */

.CustomerRequests-Body {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 20px;
}

/* ------------------------------------------------ */
/*  Confirm/Reject Form                             */
/* ------------------------------------------------ */


.CustomerRequests-ActiveRow {
    background-color: #E8F7FF;
}

.CustomerRequests-ButtonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/* Complete/Confirm Button */

.CustomerRequests-ButtonComplete {
    border: 2px solid var(--teal);
    background-color: var(--teal);
    color: white;
    border-radius: 5px;
    width: 100px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    font-size: var(--table);
    font-weight: 500;
}

/* Reject/Cancel Button */

.CustomerRequests-ButtonReject {
    border: 2px solid var(--orange);
    background-color: var(--orange);
    color: white;
    border-radius: 5px;
    width: 100px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    font-size: var(--table);
    font-weight: 500;
}

.CustomerRequests-RejectMessage {
    font-family: var(--table);
    width: 100%;
    padding: 5px 20px;
    display: inline-block;
    border: 1px solid var(--teal);
    border-radius: 4px;
    box-sizing: border-box;
    color: black;
    font-size: 15px;
}

.CustomerRequests-RejectError {
    font-family: var(--table);
    width: 100%;
    padding: 5px 20px;
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #dc3545;
    font-size: 15px;
}

.CustomerRequests-RejectMessage:hover {
    outline: none;
    border: 1px solid var(--teal);
    border-radius: 4px;
}

.CustomerRequests-RejectMessage:focus {
    outline: none;
    border: 1px solid var(--teal);
    border-radius: 4px;
}

.CustomerRequests-CompleteText {
    color: var(--teal);
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
}

.CustomerRequests-RejectText {
    color: var(--orange);
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
}