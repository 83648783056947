/* ---------------------------- */
/*  Page Styling                */
/* ---------------------------- */

.PageResponse-Container {
    display: grid;
    grid-template-rows: fit-content 1fr;
    grid-template-areas:
        'PageResponse-Header'
        'PageResponse-Body';
    text-align: center;
}

.PageResponse-Header {
    grid-area: PageResponse-Header;
}

.PageResponse-Body {
    grid-area: PageResponse-Body;
    display: grid;
}


.PageResponse-Pending {
    font-size: var(--subheading);
    font-weight: bold;
}

.PageResponse-Container-Pending-Img {
    width: 10vh;
    height: 10vh;
}