/* ======================================= */
/* Onload Page and Components              */
/* ======================================= */

.Item-Onload-Container {
    display: grid;
    grid-template-rows: 50px fit-content 1fr;
    grid-template-areas:
        'Item-Breadcrumbs'
        'Item-Title'
        'Item-Body';
}

.Item-Breadcrumbs {
    grid-area: 'Item-Breadcrumbs';
    margin: 0px;
}

.Item-Title {
    grid-area: Item-Title;
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    min-height: 200px;
}

.Item-NameRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }

.Item-Name {
    font-size: var(--heading);
    font-weight: 500;
}

.Item-Description {
    margin: 15px 0px;
    font-size: var(--body);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: horizontal;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3rem;
}

.Item-Product-Title {
    display: grid;
    grid-template-columns: 150px 1fr;
    text-align: start;
    grid-gap: 20px 30px;
    margin-left: 30px;
}

.Item-Body {
    grid-area: Item-Body;
}

.Item-Overview-Container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr .8fr;
    grid-template-areas:
        'Item-Overview Item-Overview-Details'
        'Item-Overview .';
    min-width: 600px;
    grid-gap: 3%;
}

.Item-Overview {
    grid-area: Item-Overview;
    text-align: start;
}

.Item-Fields {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Item-Field-Label {
    font-weight: 500;
}

.Item-Overview-Details {
    grid-area: Item-Overview-Details;
    text-align: left;
    font-size: var(--subheading);
}

.Item-Pricing {
    display: grid;
    text-align: start;
}

.Item-Pricing-Table {
    border: none;
}

.Item-Pricing-Table tr {
    border-bottom: 1px solid rgb(224, 224, 224);
}

.Item-Pricing-Table td {
    padding: 5px 0px;
}

.Item-Label-Tag {
    border-radius: 15px;
    padding: 5px 10px;
    margin: 5px 0px;
    background-color: #DEF6FC;
    width: fit-content;
    font-size: var(--labels);
}

.Item-NA-Label {
    background-color: #DEF6FC;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px;

}

.Item-Textarea {
    border: none;
    width: 100%;
    min-height: 200px;
    height: 100%;
    resize: none;
    cursor: default;
    outline: none;
    font-weight: normal;
}

.Item-Support {
    display: grid;
    text-align: start;
}

.Item-Support-Header {
    display: flex;
    flex-direction: column;
}

.Item-Support-Footer textarea {
    padding: 2%;
    border: none;
    width: 100%;
    height: 100%;
    resize: none;
    cursor: default;
    outline: none;
    font-weight: normal;
}

.Item-TermsService {
    font-weight: 500;
    text-align: start;
}

.Pending-Text {
    display: flex;
    flex-direction: column;
}

.Pending-Label {
    background-color: white;
    color: var(--teal);
    padding: 3px;
    border-radius: 30px;
    width: 100px;
    font-weight: 500;
    border: 2px solid var(--teal);
    text-align: center;
    margin-top: 10px;
}

/* ======================================= */
/* Page View and Components                */
/* ======================================= */

.Item-View-Container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Item-View-Header {
    font-size: var(--subheading);
    font-weight: 500;
}

.Item-View-Body {
    margin-top: 3%;
}

.Item-View-Buttons {
    margin-top: 3%;
}

/* ======================================= */
/* Page Create and Components              */
/* ======================================= */

.Item-Create-Container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Item-SubscribeForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr ;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'SubscribeForm-PTE SubscribeForm-OrderSummary';
    margin-top: 3%;
    text-align: left;
}

.SubscribeForm-PTE {
    grid-area: SubscribeForm-PTE;
  
}

.SubscribeForm-OrderSummary {
    grid-area: SubscribeForm-OrderSummary;
    background-color: var(--lightergrey);
    border-radius: 5px;
    margin: 0px 40px;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
}

.SubscribeForm-Oracle-Project-label {
    font-weight: normal;
    font-style: italic;
}

.Subscription-Item{
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    justify-content: space-between;
    text-align: right;
    font-weight: 500;
    line-height: 1.7;
}

.Subscription-Item-Icon{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.Subscription-Total{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px 0px;
    padding: 15px 0px;
    border-top: 1px solid rgb(207, 207, 207);
}

.Item-SubscribeForm-Font {
    font-size: var(--body);
    font-weight: 500;
}

/* ======================================= */
/* Page Success and Components             */
/* ======================================= */

.Item-Success-Container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Item-Success-Body {
    font-size: var(--body);
    font-weight: 500;
    margin-top: 25px;
}

.Check-Container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.Check-Container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.Check-Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    border: 1px solid grey;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.Check-Container:hover input~.Check-Checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.Check-Container input:checked~.Check-Checkmark {
    background-color: var(--teal);
}

/* Create the checkmark/indicator (hidden when not checked) */
.Check-Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.Check-Container input:checked~.Check-Checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.Check-Container .Check-Checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}