/* ------------------------------------------------------------ */
/* Product Card                                                
/* ------------------------------------------------------------ */

.Product-Card-Container {
  display: grid;
  grid-template-columns: 320px;
  grid-template-rows: 200px 200px;
  grid-template-areas:
    "Card-Header"
    "Card-Summary";
  height: 400px;
  width: 320px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 14px;
  text-align: left;
  align-items: center;
  margin: 1rem;
  position: relative;
}

.Product-Card-Container:hover {
  cursor: pointer;
  transform: scale(1.03);
}

/* ------------------------------------------------------------ */
/* Product Icon                                                 
/* ------------------------------------------------------------ */

.Card-Header {
  grid-area: Card-Header;
  justify-self: center;
  display: grid;
  grid-template-columns: 320px;
  grid-template-rows: 160px 1fr;
}

.Card-Icon {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  height: 150px;
  padding: 0.6rem;
  justify-self: center;
  align-self: center;
}

.Card-Status {
  display: flex;
  justify-content: flex-end;
  align-self: end;
  justify-self: end;
  padding: 0rem 1rem;
  gap: 0.2rem;
}

.Card-Label {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: white;
  background: #19bdc2;
  border-radius: 14px;
  padding: 0.2rem 0.4rem;
  font-size: 0.7rem;
}

/* ------------------------------------------------------------ */
/* Product Summary                                                  
/* ------------------------------------------------------------ */

.Card-Summary {
  grid-area: Card-Summary;
  background-color: #19bcc20e;
  padding: 2rem 1.5rem;
  height: 200px;
  border-radius: 0px 0px 14px 14px;
  display: grid;
  grid-template-areas: 
  "title"
  "description"
  "price"
  "ratings";
}

.Card-Title {
  grid-area: title;
  display: flex;
  justify-content: space-between;
}

.Card-Description {
  grid-area: description; 
  padding: 0.5rem 0rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /* fallback */
  height: 3.5rem; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

/* ------------------------------------------------------------ */
/* Product Rating                                              
/* ------------------------------------------------------------ */

.Card-Price {
  grid-area: price;
}

.Card-Ratings {
  grid-area: ratings;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  align-items: center;
  
}

.Card-Ratings img {
  height: 17px; 
  margin-right: 10px; 
  margin-bottom: 2px; 
  
}

