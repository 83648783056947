.QuestionLabel-Container {
    padding: 20px 0px 0px 0px;
    display: flex;
    align-content: center;
}

.QuestionLabel-Label {
    font-size: var(--body);
    font-weight: 500;
}

.QuestionLabel-Help-Icon{
    margin-left: 8px;
    width: 1rem;
    height: 1rem;
    cursor: help;
}

.QuestionLabel-Tooltip {
    display: flex;
    position: relative;
    align-self: center;
}

.QuestionLabel-Tooltip-Text {
    text-align: left;
    width: fit-content;
    min-width: 300px;
    background-color: var(--tooltip-background-colour, #394E54);
    color: #FFFFFF;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.4;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    z-index: 100;

    /* Position the tooltip */
    position: absolute;
    left: 30px;
}