/* ------------------------------------------------------------ */
/* Flow for general FinStat areas
/* ------------------------------------------------------------ */

.FinancialStatistics-Body {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
}

/* ------------------------------------------------------------ */
/* Dropdown row
/* ------------------------------------------------------------ */

.FinancialStatistics-DropdownRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  gap: 5px;
}

/* ------------------------------------------------------------ */
/* Product title row (including subscriber arrow and product icon)
/* ------------------------------------------------------------ */

.FinancialStatistics-ProductTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--subheading);
  font-weight: 500;
  gap: 10px;
  margin-bottom: 10px;
}

/* ------------------------------------------------------------ */
/* Table headers including tooltip
/* ------------------------------------------------------------ */

.FinancialStatistics-TableHeader {
  display: flex;
  flex-direction: row;
}