/* ------------------------------------------------------------ */
/* LicenseStats page                                                  
/* ------------------------------------------------------------ */

.LicenseStats-Container{
    text-align: left;
}

/* ------------------------------------------------------------ */
/* License Table Component                                               
/* ------------------------------------------------------------ */

.License-Success-Container{
    padding: 10px;
    border-radius: 5px;
    margin-top: 1%;
}

.License-Pending-Container{
    margin-top: 1%;
}

.License-Onload-Container{
    margin-top: 1%;
}

.Header-Button{
    color:  #000000e2;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.Header-Button:hover{
    padding-right: 5px;
    transition: 0.3s;
    color:  #000000;
    font-weight: bolder;
}

.Product-Name-Container{
    display: grid;
    grid-template-areas: 
    'logo button';
    grid-template-columns: 35px;
    grid-gap: 10px;
    width: inherit;
}

.Product-Name-Image{
    grid-area: logo;
    cursor: pointer;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.Product-Name-Image:hover{
    padding-top: 1px;
    padding-left: 1px;
}

.Product-Name-Button{
    grid-area: button;
    background-color: #ffffff;
    color:  #000000e2;
    cursor: pointer;
    border: none;
}

.Product-Name-Button:hover{
    transition: 0.2s;
    color:  #0864ef;
    font-weight: bold;
}

.Usage-Bar{
    min-width: 150px;
    max-width: 250px;
}